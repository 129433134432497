import React from 'react'
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import { Chart }            from 'react-chartjs-2';
import { useEffect, useState } from 'react';

const { forwardRef, useRef, useImperativeHandle } = React;

const BasicStockChart = React.forwardRef ((props, ref) => {

  const chartRef = useRef();

  const [data, setData] = useState({
    labels: [],
    datasets: [],
  });

  const [options, setOptions] = useState({
    labels: [],
    datasets: [],
  });


  useEffect(() => {

          console.log(props.stockData);

          if ( props.stockData == undefined)
            return;

          var benchmark_price_data = props.stockData.data['SPY'].chart
          var benchmark_labels = [];
          var benchmark_price = [];

          for (const [key, value] of Object.entries(benchmark_price_data))
          {
            benchmark_price.push(parseFloat(value.close_price));
            benchmark_labels.push(value.price_date_out);
          }

          var selected_symbol = props.stockData['selected_symbol']
          var selected_stock_price_data = props.stockData.data[selected_symbol].chart
          var selected_symbol_labels = [];
          var selected_symbol_price = [];

          for (const [key, value] of Object.entries(selected_stock_price_data))
          {
            selected_symbol_price.push(parseFloat(value.close_price));
            selected_symbol_labels.push(value.price_date_out);
          }

          const char_data = {
            type: 'line',
            labels: benchmark_labels,
            datasets: [
              {
                data : selected_symbol_price, radius: 2,fill: true,
                backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "rgba(75,192,192,1)",
                label : selected_symbol,
                yAxisID: 'y'
              },
              { data : benchmark_price,  radius: 2,
                borderColor: 'rgb(255, 99, 132)',
                label : "SPY",
                yAxisID: 'y1'
              }
            ]
          };


          setData(char_data);

          var start_date = props.stockData.start_date.toString().replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
          var end_date = props.stockData.end_date.toString().replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');

          const options = {
              responsive: true,
              interaction: {
                mode: 'index',
                intersect: false,
              },
              stacked: false,
              plugins: {
                title: {
                  display: true,
                  text: start_date + ' - ' + end_date
                }
              },
              scales: {
                y: {
                  type: 'linear',
                  display: true,
                  position: 'left',
                },
                y1: {
                  type: 'linear',
                  display: true,
                  position: 'right',

                  // grid line settings
                  grid: {
                    drawOnChartArea: false, // only want the grid lines for one axis to show up
                  },
                },
              }
            }

            setOptions(options)


  }, [ props.stockData ]);


  return <Line options={options} data={data} ref={chartRef}/>;
});

export default BasicStockChart;
