

const helpers = {

  amountFormatter : function(param)
  {
    return param;
  },

  valueFormatter : function(params)
  {
    if ( parseFloat(params) == undefined )
      return '';

    if ( parseFloat(params) < 0 )
      return '(' + helpers.amountFormatter(Math.abs(params)) + ')'
    else
      return helpers.amountFormatter(params);
  }

}

export default helpers;
