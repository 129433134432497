import React from 'react'
import { useEffect, useState, useRef } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import PortfolioDateGrid from './components/PortfolioDateGrid'
import MarketCapControl from './components/MarketCapControl'
import TopStocksPanel from './components/TopStocksPanel'
import BasicStockChart from './components/BasicStockChart'
import StockPickPanel from './components/StockPickPanel'


import { TailSpin } from  'react-loader-spinner'
import { Container, Row, Col } from 'react-bootstrap/';

import 'bootstrap/dist/css/bootstrap.min.css'

import './App.css';


function App() {


  const [rawData, setRawData] = useState();
  const [portfolioDates, setPortfolioDates] = useState();
  const [dateKey, setDateKey] = useState();
  const [chartData, setChartData] = useState();
  const [term, setTerm] = useState();
  const [showLoading, setShowLoading] = useState(false);

  const [portfolioDatesData, setPortfolioDatesData] = useState(undefined);
  const [portfolioPositionData, setPortfolioPositionData] = useState();
  const [portfolioDescriptions, setPortfolioDescriptions] = useState();


  const [industryData, setIndustryData] = useState([0]);
  const [businessDate, setBusinessDate] = useState([0]);

  const industryDataRef = React.useRef();
  const topStocksPanelRef = React.useRef();
  const stockPickPanelRef = React.useRef();

  const business_date = useRef(0);
  const selected_term = useRef(180);
  const row_data = useRef(null);
  const currentIndustryRankArray = React.useRef([]);

	// const url = "http://127.0.0.1:8000";
  const url = "https://www.itopstocks.com/";

  const Report_date_change = ( data ) =>
  {
    setDateKey(data)
  }

  const Term_data_change = ( term_change ) =>
  {
      console.log("**** Term data change :" + term_change);
      setTerm(term_change);
  }

  function timeout(delay: number) {
    return new Promise( res => setTimeout(res, delay) );
  }

  useEffect(() => {

    if ( term != undefined && dateKey != undefined )
    {
      dateKey['company_size'] = term;
      console.log("+++++ IndustryKey Pick :" + JSON.stringify(dateKey));

      const requestOptionsResults = { method: 'POST', headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dateKey) };

      setPortfolioPositionData(undefined)

      fetch(url + '/portfolio_position_data/',requestOptionsResults)
      .then(res => res.json())
      .then((result) =>
        {

          setPortfolioPositionData(result.data)
          //setDateKey(result)
          setPortfolioDescriptions("Positions : " + result.date_str + " - " + result.price_date_str )
          //topStocksPanelRef.current.populate_top_stock_data(result.data);
        }
      );
    }

  }, [term, dateKey]);


  const Stock_pick_change = ( data ) =>
  {
      console.log("Stock Pick : " + JSON.stringify(data))

      var id_key_request = {};
      id_key_request['start_date'] = data['open_date']
      id_key_request['end_date'] = data['price_date']
      id_key_request['selected_symbol'] = data['symbol'];
      id_key_request['tickers'] = [];
      id_key_request['tickers'].push('SPY')
      id_key_request['tickers'].push(data['symbol'])

      const requestOptionsResults = { method: 'POST', headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(id_key_request) };
      fetch(url + '/chart/', requestOptionsResults) .then(res => res.json())
      .then(result =>
          {
            setChartData(result);
          }
    );

      //setShowLoading(true);
      //stockPickPanelRef.current.populate_stock_pick(row_data.current, id_key);
  }

  const Done_loading = () =>
  {
      setShowLoading(false);
  }


  const HandleSelect = (key) => {
    //alert(key);
      //industryDataRef.current.populate_industry_data(row_data.current, business_date.current, selected_term.current);

      if (key === 0 )
      {
        ResetCurrentIndustryState();
      };
  }

  const ResetCurrentIndustryState = () =>
  {
  }

  useEffect(() => {

    const requestOptionsData = {};
    setShowLoading(true)

    fetch(url + '/portfolio_dates_data/',requestOptionsData)
    .then(res => res.json())
    .then((result) =>
      {
        setShowLoading(false)
        setRawData(result)
      }
    );

  }, []);

  useEffect(() => {

    if ( rawData !== undefined  && term !== undefined && porfolio_dates == undefined)
    {
      var porfolio_dates = []

      rawData.data.forEach((item, i) => {

        if ( item['company_size'] == 'Medium' )
          porfolio_dates.push(item)

      });

      setPortfolioDates(porfolio_dates)
    }

  }, [rawData]);

  useEffect(() => {

    if ( rawData !== undefined  && term !== undefined )
    {
      var porfolio_dates = {}

      rawData.data.forEach((item, i) => {

        if ( item['company_size'] == term )
          porfolio_dates[item['date_key']] = item

      });

      setPortfolioDatesData(porfolio_dates)
    }

  }, [term, rawData]);


  return (
    <div style={{width:'100%'}}>
    <nav>
      <Row style={{width:'100%'}}>
        <Col xs={12} md={3}>
        <h3 className="nav--logo_text">
          <div style={{padding:'10px'}}>
            Top Stocks
              <div style={{display: 'flex', justifyContent: 'left', fontSize:'14px', fontWeight:'500', paddingTop:'0px'}}>
                <a className="App-link"  href="mailto:mike.kipnis@gmail.com">Support</a>
              </div>
            </div>
          </h3>
        </Col>
        <Col xs={12} md={6}>
        </Col>
        <Col>
        </Col>
      </Row>
  </nav>
    <Container fluid>
        <div style={ ( showLoading == true ) ? {pointerEvents: "none", opacity: "0.4"} : {}}>
            <Row>
              <Col xs={12} md={3}>
              <Row>
                <MarketCapControl termChangeCallback={Term_data_change}/>
              </Row>
                <Row>
                  <PortfolioDateGrid
                    reportDateChangeCallback={Report_date_change}
                    termChangeCallback={Term_data_change}
                    portfolioDates={portfolioDates}
                    portfolioDatesData={portfolioDatesData}
                    dateKey={dateKey}
                    ref={industryDataRef}/>
                </Row>
                </Col>
                <Col xs={12} md={6}>
                  <Row style={{paddingTop:'50px', color:'#61dafb'}}>
                    <Col style={{textAlign:'Right'}}>
                      { portfolioDescriptions }
                    </Col>
                  </Row>
                  <Row>
                    <TopStocksPanel portfolioPositionData={portfolioPositionData} stockPickChangeCallback={Stock_pick_change} ref={topStocksPanelRef} />
                  </Row>
                  <Row>
                    <BasicStockChart stockData={chartData}/>
                  </Row>
                </Col>
                <Col>
                  <Row style={{paddingTop:'50px'}}>
                    <StockPickPanel stockData={chartData} externalLinks={rawData}/>
                  </Row>
                </Col>
            </Row>
          </div>
    </Container>
    </div>
  );
}

export default App;
